import React from "react";
import PropTypes from "prop-types";
import styles from "./cards.module.scss";

const Cards = props => {
  const { cards } = props;
  return (
    <section className={styles.cards}>
      <div className={styles.container}>
        {cards.map(card => (
          <div key={card.heading} className={styles.card}>
            <h4>{card.heading}</h4>
            <p>{card.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

Cards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Cards;
