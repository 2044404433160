import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Hero from "../components/hero";
import CtaBlock from "../components/cta-block";
import Cards from "../components/cards";
import Layout from "../components/layout";

const Philosophy = ({ data }) => {
  const {
    description,
    banner,
    cards,
    ctaBlock
  } = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <Seo
        title={banner.title}
        description={description}
        imagePath={banner.bannerImage.publicURL}
      />
      <Hero
        fluid={banner.bannerImage.childImageSharp.fluid}
        title={banner.title}
        blurb={banner.blurb}
      />
      <Cards cards={cards} />
      <CtaBlock
        heading={ctaBlock.heading}
        ctaLink={ctaBlock.button.link}
        ctaName={ctaBlock.button.name}
      />
    </Layout>
  );
};

Philosophy.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }).isRequired
};

export default Philosophy;

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "philosophy" } }) {
      frontmatter {
        description
        banner {
          title
          bannerImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          blurb
        }
        cards {
          heading
          text
        }
        ctaBlock {
          heading
          button {
            name
            link
          }
        }
      }
    }
  }
`;
